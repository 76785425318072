import React from 'react';
import '../css/home.css';
import '../css/all.css'
import AnimatedPage from '../components/AnimatedPage'
import { useEffect, useState } from 'react';

export default function Listen() {




  return (
    <AnimatedPage>
          <div>
          
            <h2>Current Song</h2>
        </div>
    </AnimatedPage>

  )
};
